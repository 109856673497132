.countries-table-header {
    background-color: #CCE5FF;
    cursor: pointer;
}

.country-row-green {
    background-color: #D4EDDA;
}

.country-row-yellow {
    background-color: #FFF3CD;
}

.country-row-red {
    background-color: #F8D7DA;
}

.table-countries {
    margin-top: 40px;
}

.country-link {
    text-decoration: none;
}