.flex {
    display: flex;
}

.button-minified {
    height: min-content;
}

.centered-vertical {
    align-items: center;
}

.centered-horizontal {
    text-align: center;
}

.spaced-between {
    justify-content: space-between;
}

.minified {
    height: min-content;
}

.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.margin-horizontal-small {
    margin: 0 10px;
}

.margin-vertical-small {
    margin: 10px 0;
}

.margin-small {
    margin: 5px 10px;
}

.centered-outside {
    width: auto;
    margin: 0 auto;
}